import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 710.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,710.000000) scale(0.100000,-0.100000)">
          <path d="M3400 5370 c-501 -58 -956 -315 -1266 -715 -100 -129 -215 -345 -272
-510 -212 -611 -85 -1299 333 -1795 104 -124 332 -318 458 -389 118 -67 257
-131 359 -165 378 -127 798 -127 1177 0 109 37 290 122 383 180 173 109 362
280 494 449 86 111 209 336 268 492 29 79 75 271 90 382 43 307 -6 679 -127
951 -114 259 -226 422 -415 607 -268 262 -599 431 -977 498 -106 19 -398 28
-505 15z m174 -126 c9 -22 -1 -34 -29 -34 -24 0 -24 0 -18 -80 l6 -80 -26 0
c-27 0 -27 -1 -40 135 -1 11 -12 21 -26 25 -14 3 -26 11 -29 18 -6 18 35 31
99 31 43 1 58 -3 63 -15z m238 -99 c15 -55 24 -103 20 -107 -13 -13 -50 3 -55
23 -6 24 -72 27 -81 4 -7 -18 -56 -20 -56 -2 0 7 10 33 21 57 12 25 30 64 40
88 17 40 22 43 51 40 32 -3 33 -4 60 -103z m-494 2 c29 -47 52 -89 52 -95 0
-16 -39 -21 -54 -6 -24 23 -86 15 -86 -12 0 -15 -36 -38 -49 -30 -7 5 12 183
23 213 4 11 44 22 57 16 3 -1 29 -40 57 -86z m864 10 c5 -7 7 -21 6 -32 -4
-25 -37 -21 -56 7 -10 14 -17 17 -25 9 -8 -8 -1 -21 26 -45 20 -18 37 -43 37
-55 0 -30 -35 -61 -69 -61 -34 0 -78 35 -83 65 -4 27 27 31 49 6 18 -21 43
-28 43 -13 0 5 -16 23 -35 41 -49 44 -47 90 5 109 27 10 87 -8 102 -31z
m-1131 -17 c25 -14 49 -62 49 -99 0 -41 -41 -86 -95 -104 -52 -18 -48 -22 -91
88 -20 51 -28 84 -23 90 28 29 124 44 160 25z m1304 -42 c27 -13 45 -29 45
-39 0 -24 -20 -29 -52 -13 -20 10 -29 11 -34 2 -7 -10 15 -33 39 -41 6 -2 8
-11 4 -21 -5 -13 -14 -16 -42 -10 -28 5 -35 3 -35 -10 0 -9 11 -21 25 -26 27
-10 36 -50 11 -50 -28 0 -106 40 -106 54 0 21 79 176 90 176 5 0 30 -10 55
-22z m-1535 -125 c79 -66 80 -67 62 -86 -16 -16 -23 -17 -39 -7 -16 10 -24 10
-46 -5 -15 -9 -27 -26 -27 -36 0 -16 -36 -46 -45 -37 -6 6 -35 161 -35 185 0
23 23 52 42 53 4 0 44 -30 88 -67z m1733 12 c39 -30 47 -40 47 -68 0 -42 -10
-56 -46 -66 -25 -6 -29 -12 -32 -49 -3 -46 -7 -49 -36 -27 -14 10 -17 23 -14
54 2 23 1 41 -1 41 -3 0 -15 -12 -27 -27 -19 -24 -24 -26 -38 -14 -9 8 -14 21
-11 29 10 27 97 162 104 162 3 0 27 -16 54 -35z m-677 -26 c348 -67 680 -275
877 -549 26 -36 51 -70 56 -76 20 -25 95 -163 118 -219 77 -187 104 -320 110
-532 4 -134 1 -195 -12 -275 -39 -233 -130 -451 -262 -628 -500 -667 -1433
-783 -2079 -259 -174 141 -328 345 -410 544 -78 188 -106 316 -111 527 -8 283
34 478 159 722 101 198 281 399 479 533 177 121 442 213 684 237 81 8 289 -5
391 -25z m-1313 -52 c7 -7 36 -41 66 -75 51 -60 52 -64 36 -82 -20 -22 -38
-18 -55 16 -21 39 -30 28 -14 -18 13 -37 13 -44 -1 -57 -13 -13 -20 -13 -61 7
l-47 23 27 -31 c14 -17 26 -35 26 -40 0 -11 -28 -32 -38 -28 -22 8 -123 139
-120 154 7 38 34 39 97 5 34 -18 61 -28 61 -23 0 5 -9 35 -20 66 -19 56 -19
57 0 76 23 23 28 24 43 7z m2162 -50 c4 -13 -3 -43 -14 -71 -12 -26 -21 -52
-21 -58 0 -5 25 5 55 22 59 34 90 36 90 6 0 -13 -24 -32 -79 -62 -85 -48 -100
-51 -122 -30 -20 21 -18 34 22 130 36 86 58 107 69 63z m-2340 -232 c58 -54
68 -68 59 -82 -6 -9 -15 -18 -20 -20 -5 -2 -42 28 -83 65 -70 64 -73 68 -57
85 10 9 20 17 24 17 4 -1 39 -30 77 -65z m2543 53 c22 -22 11 -43 -53 -98 -71
-61 -86 -68 -104 -46 -10 12 1 27 61 85 40 39 75 71 79 71 3 0 10 -5 17 -12z
m-2635 -153 c75 -52 79 -57 71 -79 -8 -20 -33 -20 -62 0 -21 15 -25 15 -43 -2
-25 -23 -24 -38 5 -54 32 -16 38 -33 21 -56 -12 -17 -18 -15 -94 36 -78 53
-93 74 -70 97 8 8 20 6 44 -9 l34 -19 17 27 c18 26 18 27 -9 48 -30 24 -33 36
-15 54 16 16 20 14 101 -43z m2737 -2 c16 -9 33 -27 39 -40 16 -36 14 -93 -4
-100 -21 -7 -27 0 -34 43 -3 20 -13 41 -22 47 -41 26 -109 -32 -79 -68 7 -8
24 -21 39 -28 29 -14 40 -44 18 -53 -8 -3 -31 2 -51 10 -77 32 -87 136 -17
183 40 27 73 29 111 6z m-2932 -205 c1 -8 -9 -26 -23 -40 -37 -38 -14 -43 30
-8 44 35 65 37 90 10 35 -38 15 -109 -37 -131 -53 -22 -62 23 -12 56 14 9 22
20 19 25 -10 15 -18 12 -53 -20 -39 -35 -64 -38 -92 -10 -34 34 -20 98 27 124
32 17 47 16 51 -6z m3082 -23 c27 -43 31 -88 9 -93 -10 -2 -21 8 -29 28 -7 16
-17 30 -22 30 -16 0 -21 -30 -7 -46 10 -12 9 -18 -5 -32 -16 -16 -18 -16 -31
2 -8 10 -16 24 -18 29 -2 6 -11 8 -20 4 -14 -5 -15 -10 -6 -31 12 -26 7 -56
-10 -56 -11 0 -49 62 -56 93 -5 20 4 27 77 64 46 23 86 42 90 42 3 1 16 -15
28 -34z m-2400 -2069 c9 -12 5 -27 -25 -77 -28 -48 -33 -64 -24 -75 23 -27 45
-14 80 46 35 62 48 70 69 45 10 -12 5 -26 -24 -79 -43 -76 -76 -95 -131 -72
-65 27 -70 72 -20 163 36 64 53 75 75 49z m1726 -37 c30 -28 40 -80 24 -119
-10 -24 -92 -90 -112 -90 -11 0 -99 162 -96 177 2 8 23 26 48 40 53 28 100 26
136 -8z m-1526 -69 c13 -7 23 -30 31 -70 6 -33 15 -60 20 -60 4 0 14 18 20 41
14 46 33 59 55 37 13 -13 10 -26 -22 -106 -33 -83 -39 -92 -58 -86 -28 9 -32
17 -45 85 -7 33 -14 59 -16 59 -2 0 -11 -22 -20 -50 -17 -51 -21 -55 -49 -44
-21 8 -20 20 9 95 49 124 43 116 75 99z m1280 -19 c0 -12 -11 -23 -31 -30 -32
-11 -36 -38 -5 -33 9 1 24 3 33 5 12 1 19 -6 21 -19 2 -16 -4 -23 -22 -28 -15
-3 -26 -13 -26 -21 0 -22 14 -25 42 -11 27 14 48 5 48 -20 0 -11 -107 -61
-114 -53 -6 6 -76 177 -76 184 0 9 83 44 108 44 15 1 22 -5 22 -18z m-183 -58
c4 -9 2 -18 -4 -20 -5 -2 -17 -9 -26 -16 -15 -11 -15 -18 3 -82 19 -72 16 -90
-16 -83 -14 2 -24 22 -38 77 -19 72 -20 73 -48 67 -24 -5 -28 -2 -28 18 0 19
9 26 53 38 71 21 96 21 104 1z m-841 -8 c5 -12 -5 -71 -22 -136 -3 -12 5 -20
26 -27 31 -11 35 -19 22 -38 -5 -9 -21 -9 -59 -1 -62 13 -62 12 -32 132 18 70
25 85 41 85 10 0 21 -7 24 -15z m619 -62 c4 -27 11 -72 17 -101 5 -30 8 -55 6
-57 -1 -2 -14 -5 -28 -7 -23 -3 -25 0 -38 90 -15 101 -13 122 17 122 16 0 21
-9 26 -47z m-432 7 c-19 -165 -25 -191 -47 -188 -18 3 -21 9 -20 48 0 25 4 71
8 103 7 56 9 58 34 55 17 -2 26 -9 25 -18z m159 -50 c12 -33 26 -60 29 -60 3
0 18 27 32 61 24 55 28 60 54 57 l28 -3 0 -100 c0 -95 -1 -100 -22 -103 -20
-3 -22 2 -25 50 l-3 53 -20 -53 c-17 -45 -23 -52 -44 -50 -17 2 -28 15 -42 48
l-18 45 0 -47 c-1 -44 -3 -48 -26 -48 -19 0 -25 5 -26 23 -1 12 -3 53 -5 92
-5 86 -2 95 35 95 27 0 31 -5 53 -60z"/>
<path d="M3736 5154 c-10 -25 -7 -34 9 -34 8 0 15 9 15 19 0 26 -16 35 -24 15z"/>
<path d="M3240 5125 c0 -16 6 -25 15 -25 16 0 19 9 9 34 -9 25 -24 19 -24 -9z"/>
<path d="M2962 5087 c-8 -9 -6 -24 8 -55 21 -47 26 -49 59 -26 17 13 21 22 16
42 -11 44 -60 67 -83 39z"/>
<path d="M2750 4937 c0 -27 13 -36 26 -16 3 6 -1 18 -10 27 -15 15 -16 14 -16
-11z"/>
<path d="M4506 4934 c-11 -27 -7 -35 16 -32 29 4 34 24 10 37 -16 8 -21 7 -26
-5z"/>
<path d="M3025 4484 c-11 -2 -45 -9 -75 -15 -150 -30 -282 -136 -335 -269 -28
-71 -30 -82 -30 -220 0 -138 2 -149 30 -219 33 -80 84 -145 149 -190 69 -48
83 -48 119 -5 18 21 67 77 110 125 42 48 77 91 77 95 0 5 -16 19 -35 31 -43
28 -65 70 -72 137 -10 100 37 188 116 214 24 9 195 12 588 12 515 0 554 1 567
18 27 32 82 107 116 157 18 28 36 52 39 55 10 9 51 69 51 75 0 6 -1384 5
-1415 -1z"/>
<path d="M3213 3763 c-10 -10 -43 -48 -73 -83 -30 -35 -64 -74 -75 -85 -45
-47 -87 -106 -80 -113 3 -4 257 -9 563 -12 l557 -5 46 -27 c62 -36 84 -87 84
-188 -1 -97 -24 -145 -86 -180 -44 -25 -45 -25 -351 -28 l-308 -3 0 135 0 136
-175 0 -175 0 0 -135 0 -135 -145 0 -145 0 -78 -107 c-42 -58 -92 -126 -109
-150 -18 -24 -33 -45 -33 -48 0 -10 1446 -5 1533 6 165 20 276 76 359 181 73
90 93 167 93 343 -1 131 -3 145 -30 214 -53 132 -162 227 -315 272 -72 21 -95
23 -557 27 -459 3 -482 3 -500 -15z"/>
<path d="M4394 2147 c-8 -21 42 -98 60 -91 24 9 36 48 22 75 -14 29 -73 40
-82 16z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
